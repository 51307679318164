<template>
  <div id="homeWrapper">
    <div id="homeHeader">
      <div class="anim">
        <img :src="a" />
      </div>
      <div class="anim" style="margin-left: 3vw">
        <router-link to="/aktuelni-katalog-namjestaja"
          ><img src="/images/online-katalog2.gif"
        /></router-link>
      </div>
    </div>
    <div id="dddWrapp">
      <carousel-3d
        v-if="elemLoaded"
        :autoplay="true"
        :display="isMobile ? 5 : 9"
        :animationSpeed="1000"
        :controlsVisible="true"
        :perspective="40"
        :space="300"
        :height="360"
        :onMainSlideClick="oganj"
      >
        <slide v-for="(item, index) in itemList" :key="index" :index="index">
          <div class="caroItem">
            <img
              :src="'/images/namjestaj/' + item.art_id + '/0.jpg'"
              :alt="item.pod_naziv + item.art_naziv"
            />
            <div v-if="item.art_sastav == 1">
              <div class="naziv">
                {{ item.pod_naziv }} <span>{{ item.art_naziv }}</span>
                {{ item.art_boja }}
              </div>
              <div class="popCijena">
                <div v-if="item.art_gornja != 0">
                  {{ item.art_gornja }}<span>€</span>
                </div>
                <div v-if="item.art_srednja != 0">
                  {{ item.art_srednja }}<span>€</span>
                </div>
              </div>
              <div v-if="item.art_donja != 0" class="cijena">
                {{ item.art_donja }}<span>€</span>
              </div>
              <div class="popustWrapper">
                <div class="popust" v-bind:class="'akcija_popust'+item.art_rasprodaja" v-if="item.art_gornja != 0">
                  <div>
                    {{ calcPopustB(item.art_donja, item.art_srednja)
                    }}<span>%</span>
                  </div>
                </div>
                <div class="popust" v-bind:class="'akcija_popust'+item.art_rasprodaja" v-if="item.art_srednja != 0">
                  <div>
                  <!--
                    {{
                      calcPopustA(
                        item.art_donja,
                        item.art_srednja,
                        item.art_gornja
                      )
                    }}
                    -->
                    30
                    <span>%</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="item.art_sastav == 2">
              <div v-if="elemLoaded">
                <div
                  class="naziv"
                  v-if="item.art_naziv == ''"
                  v-html="sastavNaziv(item.art_artikli)"
                >
                  <span v-if="item.art_boja">{{ item.art_boja }}</span>
                </div>
                <div class="naziv" v-else>
                  {{ item.pod_naziv }} <span>{{ item.art_naziv }}</span>
                  {{ item.art_boja }}
                </div>
                <div class="popCijena">
                  <div v-if="item.art_gornja != 0">
                    {{ item.art_gornja }}<span>€</span>
                  </div>
                  <div v-if="item.art_srednja != 0">
                    {{ item.art_srednja }}<span>€</span>
                  </div>
                </div>
                <div v-if="item.art_donja != 0" class="cijena">
                  {{ item.art_donja }}<span>€</span>
                </div>
              </div>
              <div class="popustWrapper">
                <div class="popust" v-bind:class="'akcija_popust'+item.art_rasprodaja" v-if="item.art_gornja != 0">
                  <div>
                    {{ calcPopustB(item.art_donja, item.art_srednja)
                    }}<span>%</span>
                  </div>
                </div>
                <div class="popust" v-bind:class="'akcija_popust'+item.art_rasprodaja" v-if="item.art_srednja != 0">
                  <div>
                  <!--
                    {{
                      calcPopustA(
                        item.art_donja,
                        item.art_srednja,
                        item.art_gornja
                      )
                    }}
                    -->
                    30
                    <span>%</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="item.art_sastav == 3">
              <div v-if="elemLoaded">
                <div class="naziv">
                  {{ item.pod_naziv }} <span>{{ item.art_naziv }}</span>
                  {{ item.art_boja }}
                </div>
                <div class="popCijena">
                  <div v-if="item.art_gornja != 0">
                    {{ item.art_gornja }}<span>€</span>
                  </div>
                  <div v-if="item.art_srednja != 0">
                    {{ item.art_srednja }}<span>€</span>
                  </div>
                </div>
                <div v-if="item.art_donja != 0" class="cijena">
                  {{ item.art_donja }}<span>€</span>
                </div>
              </div>
            </div>
          </div>
        </slide>
      </carousel-3d>
      <div style="display: block; height: 5vw"></div>
    </div>
    <div id="homeNavWrap">
      <router-link
        v-for="item in kategorije"
        :key="item.a"
        :to="'/namjestaj/' + item.b"
      >
        <div class="homeNavItem">
          <img :src="'/images/menu_icons/' + item.b + '.png'" />
          <div class="homeNavItemText">{{ item.a }}</div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
  components: {
    Carousel3d,
    Slide
  },
  data() {
    return {
      a: "/images/slider/1.gif",
      cntr: 2,
      tempList: [],
      itemList: [],
      elemList: [],
      elemLoaded: false,
      kategorije: [
        { a: "Dnevne garniture koža", b: "dnevne-garniture-koza" },
        {
          a: "Dnevne garniture eko-koža / štof",
          b: "dnevne-garniture-eko-koza-stof"
        },
        { a: "Kreveti", b: "kreveti" },
        { a: "Kuhinje", b: "kuhinje" },
        { a: "Trpezarije", b: "trpezarije" },
        { a: "Ležajevi", b: "lezajevi" },
        { a: "Dječije sobe", b: "djecije-sobe" },
        { a: "Spavaće sobe", b: "spavace-sobe" },
        { a: "Dušeci", b: "duseci" },
        { a: "Plakari", b: "plakari" },
        { a: "Predsoblja", b: "predsoblja" },
        { a: "Kancelarijski namještaj", b: "kancelarijski-namjestaj" },
        { a: "TV vitrine i regali", b: "tv-vitrine-i-reglai" },
        { a: "Klub stolovi", b: "klub-stolovi" },
        { a: "Ogledala", b: "ogledala" },
        { a: "Baštenski program", b: "bastenski-program" }
      ]
    };
  },
  created() {
    this.loadItems();
  },
  mounted() {
    setInterval(() => {
      this.a = "/images/slider/" + this.cntr + ".gif";
      this.cntr++;
      if (this.cntr == 4) this.cntr = 1;
    }, 5000);
    window.scrollTo(0, 0);
  },
  methods: {
    oganj(a) {
      var item = this.itemList[a.index];
      this.$router.push(
        "/namjestaj/" +
          item.kat_url +
          "/" +
          item.art_id +
          "/" +
          item.pod_url +
          "-" +
          item.art_naziv.toLowerCase().replace(" ", "-")
      );
    },
    calcPopustA(donja, srednja, gornja, gornja2) {
      var a = 0.0;
      if (gornja != 0) {
        a = ((gornja - srednja) / gornja) * 100;
        if (a - Math.floor(a) >= 0.9) return Math.ceil(a);
        return Math.floor(a);
      } else if (gornja2 != 0) {
        a = ((gornja2 - srednja) / gornja2) * 100;
        if (a - Math.floor(a) >= 0.9) return Math.ceil(a);
        return Math.floor(a);
      } else {
        a = ((srednja - donja) / srednja) * 100;
        if (a - Math.floor(a) >= 0.9) return Math.ceil(a);
        return Math.floor(a);
      }
    },
    calcPopustB(donja, srednja) {
      var a = ((srednja - donja) / srednja) * 100;
      if (a - Math.floor(a) >= 0.9) return Math.ceil(a);
      return Math.floor(a);
    },
    sastavNaziv(item) {
      var a = "";
      if (item.length > 0)
        JSON.parse(item).map(val => {
          a =
            a +
            (val.kolicina > 1 ? val.kolicina + "x " : "") +
            this.elemList[val.sifra].pod_naziv +
            " <span>" +
            this.elemList[val.sifra].art_naziv +
            "</span> + ";
        });
      return a.substring(0, a.length - 3);
    },
    getUnique(a) {
      if (a.art_artikli != "[]") {
        JSON.parse(a.art_artikli).map(item => (this.elemList[item.sifra] = {}));
      }
      return a;
    },
    loadElems() {
      if (Object.keys(this.elemList).length > 0)
        fetch(
          "/kaoitemlist.php?elems=" + Object.keys(this.elemList).join(",")
        ).then(response => {
          response.json().then(data => {
            data.map(item => (this.elemList[item.art_id] = item));
            this.elemLoaded = true;
          });
        });
    },
    loadItems() {
      this.itemList = [];
      fetch("/kaoitemlist.php?nasl=1").then(response => {
        response.json().then(data => {
          this.tempList = data;
          while (this.tempList.length != 0) {
            this.itemList.push(this.getUnique(this.tempList.shift()));
          }
          this.loadElems();
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
// MOBILE =================================================================================================
@media only screen and (max-width: 600px) {
  ::v-deep .next {
    right: -18px;
  }

  #homeWrapper {
    width: 100vw;
    background-color: #0a0a0a;
  }
  #homeHeader {
    position: relative;
    width: 100vw;
    background-image: URL("/images/slider/pozadina1.jpg");
    background-size: 230vw;
    background-position-x: 73%;
    background-position-y: top;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column-reverse;
    padding-top: 307px;
  }

  .anim {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .anim img {
    width: 85%;
    margin: 50px 0px;
  }

  .anim a {
    width: 79%;
    display: flex;
    justify-content: center;
  }

  .carousel-3d-container {
    margin: 0px !important;
    height: 100vw !important;
  }

  .carousel-3d-container {
    .carousel-3d-slider {
      cursor: pointer;
    }
    .carousel-3d-slide {
      padding: 2vw;
      background-color: #fff;
    }
  }

  .caroItem {
    position: relative;
    width: 100%;
    transition: transform 0.3s;
    padding-bottom: 0.6vw;
    a {
      display: flex;
      flex-direction: column;
    }
    img {
      position: relative;
      width: 100%;
    }
    .naziv {
      padding: 0vw 0.6vw;
      font-size: 5vw;
      color: #444;
      font-weight: 200;
      margin: 0.3vw 0vw;
      span {
        font-size: 6vw;
        font-weight: 500;
      }
    }
    .cijena {
      color: #f00;
      font-size: 8vw;
      padding: 0vw 0.6vw;
      span {
        font-size: 0.8em;
      }
    }
    .popCijena {
      display: flex;
      padding: 0vw 0.6vw;
      font-size: 4vw;
      div {
        margin: 0px 3vw 0px 0px;
        text-decoration: line-through;
      }
    }

    .popustWrapper {
      width: 27vw;
      height: 16vw;
      position: absolute;
      right: 0.5vw;
      bottom: 1.3vw;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .popust {
      width: 13vw;
      height: 17vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 7vw;
      padding: 7vw 0vw 0vw;
      box-sizing: border-box;
      letter-spacing: -0.1vw;
      color: #e00;
    }

    .popust span {
      font-size: 0.7em;
    }

    .popdod {
      background-image: url("/images/popusti/dodatni1.jpg");
      background-size: cover;
    }
    .popdod2 {
      background-image: url("/images/popusti/akcija2.jpg");
      background-size: cover;
    }

    .popsniz {
      background-image: url("/images/popusti/snizenje1.jpg");
      background-size: cover;
    }
    
    .popsniz2 {
        background-image: url("/images/popusti/eksponat.png");
        background-size: cover;
      }
      
  .akcija_popust0 {
	background-image: url('/images/popusti/snizenje1.jpg');
	background-size: cover;
	color: #e00 !important;
  }
  .akcija_popust1 {
	background-image: url('/images/popusti/eksponat.jpg');
	background-size: cover;
	color: #e00 !important;
  }
  .akcija_popust2 {
	background-image: url("/images/popusti/nr1.jpg");
	background-size: cover;
	color: #e00 !important;
  }
  .akcija_popust3 {
	background-image: url('/images/popusti/na1.jpg');
	background-size: cover;
	color: #e00 !important;
  }

    .popNat {
      font-size: 0.5vw;
      text-align: center;
      line-height: 1.3;
      top: 0px;
      color: #555;
    }
  }

  #homeNavWrap {
    display: flex;
    width: 100vw;
    flex-wrap: wrap;
    padding: 10vw 5vw 5vw;
    box-sizing: border-box;
  }

  .homeNavItem {
    background-color: #1a1b1c;
    width: 24vw;
    color: #f0f0f0;
    font-size: 3vw;
    font-weight: 300;
    text-align: center;
    padding: 0.5vw 2vw 1vw;
    text-transform: uppercase;
    margin: 0.8vw;
    img {
      position: relative;
      width: 70%;
    }
  }

  .homeNavItem:hover {
    background-color: #3a3b3c;
    cursor: pointer;
  }
}

// DESKTOP =================================================================================================

@media only screen and (min-width: 601px) {
  #homeNavWrap {
    display: flex;
    width: 100vw;
    flex-wrap: wrap;
    padding: 1vw 12vw 5vw;
    box-sizing: border-box;
  }

  .homeNavItem {
    background-color: #1a1b1c;
    width: 12vw;
    color: #f0f0f0;
    font-size: 1vw;
    font-weight: 100;
    text-align: center;
    padding: 0.5vw 2vw 1vw;
    text-transform: uppercase;
    margin: 1.2vw;
    opacity: 0.6;
    transition: all 0.2s;
    img {
      position: relative;
      width: 70%;
    }
  }

  .homeNavItem:hover {
    background-color: #3a3b3c;
    cursor: pointer;
    opacity: 1;
  }

  #homeWrapper {
    width: 100vw;
    background-color: #0a0a0a;
  }
  #homeHeader {
    position: relative;
    width: 100vw;
    height: 55vw;
    background-image: URL("/images/slider/pozadina1.jpg");
    background-size: cover;
    background-position-x: center;
    display: flex;
  }

  .anim {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    align-items: center;
    left: 4vw;
  }

  .anim img {
    height: 50%;
    margin-top: 13vw;
  }

  .anim a {
    display: block;
    height: 76%;
  }

  .carousel-3d-container {
    margin: 0px !important;
    height: 25vw !important;
  }

  .carousel-3d-container {
    .carousel-3d-slider {
      cursor: pointer;
    }
    .carousel-3d-slide {
      padding: 0.5vw;
      background-color: #fff;
      cursor: pointer;
    }
  }

  .caroItem {
    position: relative;
    width: 100%;
    transition: transform 0.3s;
    padding-bottom: 0.6vw;
    a {
      display: flex;
      flex-direction: column;
    }
    img {
      position: relative;
      width: 100%;
    }
    .naziv {
      padding: 0vw 0.6vw;
      font-size: 0.9vw;
      color: #444;
      font-weight: 200;
      margin: 0.3vw 0vw;
      span {
        font-size: 1.2vw;
        font-weight: 500;
      }
    }
    .cijena {
      color: #f00;
      font-size: 1.7vw;
      padding: 0vw 0.6vw;
      span {
        font-size: 0.8em;
      }
    }
    .popCijena {
      display: flex;
      padding: 0vw 0.6vw;
      font-size: 1vw;
      div {
        margin: 0px 0.2vw;
        text-decoration: line-through;
      }
    }

    .popust {
      width: 3.5vw;
      height: 4.5vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 2vw;
      padding: 1.7vw 0vw 0vw;
      box-sizing: border-box;
      letter-spacing: -0.1vw;
      color: #e00;
    }

    .popust span {
      font-size: 1vw;
    }

    .popdod {
      background-image: url("/images/popusti/dodatni1.jpg");
      background-size: cover;
    }
    .popdod2 {
      background-image: url("/images/popusti/akcija2.jpg");
      background-size: cover;
    }

    .popsniz {
      background-image: url("/images/popusti/snizenje1.jpg");
      background-size: cover;
    }
    
    .popsniz2 {
        background-image: url("/images/popusti/eksponat.png");
        background-size: cover;
      }
      
      
      
  .akcija_popust0 {
	background-image: url('/images/popusti/snizenje1.jpg');
	background-size: cover;
	color: #e00 !important;
  }
  .akcija_popust1 {
	background-image: url('/images/popusti/eksponat.jpg');
	background-size: cover;
	color: #e00 !important;
  }
  .akcija_popust2 {
	background-image: url("/images/popusti/nr1.jpg");
	background-size: cover;
	color: #e00 !important;
  }
  .akcija_popust3 {
	background-image: url('/images/popusti/na1.jpg');
	background-size: cover;
	color: #e00 !important;
  }

    .popNat {
      font-size: 0.5vw;
      text-align: center;
      line-height: 1.3;
      top: 0px;
      color: #555;
    }
  }

  .popustWrapper {
    width: 7.5vw;
    height: 3.5vw;
    position: absolute;
    right: 0.5vw;
    bottom: 1.3vw;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
</style>
